import { Buttons } from '../../Components/Buttons/Buttons'
import { Description } from '../../Components/Description/Description'
import { Map } from '../../Components/Map/Map'
import { UpperBlock } from '../../Components/UpperBlock/UperBlock'
import s from './Main.module.scss'

export const Main = () => {

    return (
        <main className={s.main}>
            <div className={s.main_upper}>
                <UpperBlock />
                <Buttons />
            </div>
            <Description />
            <Map />
        </main>
    )
}