import s from './AudioPlayer.module.scss'
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Pause } from './icons/pause.svg';
import { ReactComponent as SoundOff } from './icons/soundOff.svg';
import { ReactComponent as SoundOn } from './icons/soundOn.svg';
import { ReactComponent as Speed } from './icons/speed.svg';
import { ReactComponent as Prev } from './icons/previous.svg';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useRef } from 'react';



export const NavigationPannelMobile = ({ handlePlayPause, isPlaying, progressMin, progressSec, currentTime, duration, durationMin, 
    durationSec, handleMuteUnmute, isMuted, setPlaybackRate, playbackRate, audioRef, changePlaybackRate }) => {

    const { points } = useSelector(state => state.points);

    const { id } = useParams();

    const progressRef = useRef(null);

    const handleProgressBarClick = (e) => {
        const rect = progressRef.current.getBoundingClientRect();
        const totalWidth = rect.width;
        const clickX = e.clientX - rect.left;
        const duration = audioRef.current.duration;
        const newTime = (clickX / totalWidth) * duration;
        audioRef.current.currentTime = newTime;
    };

    return (
        <div className={s.player_mobile}>
            <div className={s.top_string}>
                <div className={s.timer}>{progressMin < 10 ? `0${progressMin}` : progressMin}:
                    {progressSec < 10 ? `0${progressSec}` : progressSec}</div>
                <div
                    className={s.progress_container}
                    onClick={handleProgressBarClick}
                    ref={progressRef}
                    style={{
                        backgroundColor: '#ECF2F4',
                        height: '15px',
                        width: '100%',
                        cursor: 'pointer',
                        borderRadius: '10px',

                    }}
                >
                    <div
                        className="progress-filled"
                        style={{
                            height: '100%',
                            width: `${(currentTime / duration) * 100}%`,
                            backgroundColor: '#538146',
                            borderRadius: '10px',
                            transition: 'width 0.7s ease'
                        }}
                    />
                </div>
                <div className={s.timer}>{durationMin < 10 ? `0${durationMin}` : durationMin}:
                    {durationSec < 10 ? `0${durationSec}` : durationSec}</div>
            </div>
            <div className={s.bottom_string}>
                <div className={s.svg_btn} onClick={handleMuteUnmute}>{isMuted ? <SoundOff /> : <SoundOn />}</div>
                <NavLink className={s.svg_btn} to={points.length > 0 && id >= points.at(0).place_id ? `/points/${Number(id) - 1}` : ''}>
                    <Prev />
                </NavLink>
                <div className={s.svg_btn} onClick={handlePlayPause}>{isPlaying ? <Pause /> : <Play />}</div>
                <NavLink className={`${s.svg_btn} ${s.btn_next}`} to={points.length > 0 && id < points.length - 1 ? `/points/${Number(id) + 1}` : ''}>
                    <Prev />
                </NavLink>
                <div onClick={() => setPlaybackRate(!playbackRate)} className={`${s.svg_btn} ${s.speed_body}`}><Speed />
                    {playbackRate &&
                        <div className={s.speed_container}>
                            <div style={audioRef.current.playbackRate === 0.5 ? { backgroundColor: '#538146', color: '#FFFFFF' } : null}
                                className={s.speed_btn} onClick={() => changePlaybackRate(0.5)}>0.5x</div>
                            <div style={audioRef.current.playbackRate === 0.75 ? { backgroundColor: '#538146', color: '#FFFFFF' } : null}
                                className={s.speed_btn} onClick={() => changePlaybackRate(0.75)}>0.75x</div>
                            <div style={audioRef.current.playbackRate === 1 ? { backgroundColor: '#538146', color: '#FFFFFF' } : null}
                                className={s.speed_btn} onClick={() => changePlaybackRate(1)}>1x</div>
                            <div style={audioRef.current.playbackRate === 1.25 ? { backgroundColor: '#538146', color: '#FFFFFF' } : null}
                                className={s.speed_btn} onClick={() => changePlaybackRate(1.25)}>1.25x</div>
                            <div style={audioRef.current.playbackRate === 1.5 ? { backgroundColor: '#538146', color: '#FFFFFF' } : null}
                                className={s.speed_btn} onClick={() => changePlaybackRate(1.5)}>1.5x</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}