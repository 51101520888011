import { useSelector } from 'react-redux'
import s from './Map.module.scss'
import { MapBox } from '../MapBox/MapBox'
import { useTranslation } from 'react-i18next'


export const Map = () => {
    const { points } = useSelector(state => state.points)

    const { t } = useTranslation()

    return (
        <section className={s.wrapper}>
            <div className={s.map}>
                <div className={s.title}>
                    {t('titleMap')}
                </div>
                <div className={s.mab_body}>
                    <div className={s.points}>
                        {points.map((e, index) =>
                            <div className={s.point_title} key={index}>
                                <div>{`${e.place_id}.`}</div>
                                <div>{e.place_name_locale}</div>
                            </div>
                        )}
                    </div>
                    <div className={s.map_img}>
                        <MapBox points={points}/>
                    </div>
                </div>
            </div>

        </section>
    )
}