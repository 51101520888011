import { useEffect, useRef, useState } from 'react'
import { ReactComponent as Earth } from './language.svg'
import s from './SelectLanguage.module.scss'
import i18n from '../../../i18n';
import { useDispatch } from 'react-redux';
import { getPoints } from '../../../store/pointsSlice';

export const SelectLanguage = () => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'BE')
  const [isOpen, setIsOpen] = useState(false)
  const optionBodyRef = useRef(null)
  const dispatch = useDispatch()

  const changeLanguage = (e) => {
    i18n.changeLanguage(e);
    setLanguage(e)
  };

  useEffect(() => {
    localStorage.setItem('language', language)

    const languages = () => {
      switch (language) {
        case 'RU':
          return 1
        case 'BE':
          return 2
        case 'EN':
          return 3
        default:
          return 1
      }
    }
    dispatch(getPoints(languages))
    document.addEventListener('click', closeOptionBody)

    return () => {
      document.removeEventListener('click', closeOptionBody)
    };
  }, [language, dispatch])

  const closeOptionBody = (event) => {
    if (optionBodyRef.current && !optionBodyRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  };

  return (
    <div
      ref={optionBodyRef}
      className={s.languages_container}
      onClick={() => setIsOpen(!isOpen)}>
      <div className={s.selected_language}>
        <Earth />
      </div>
      {isOpen ?
        <div className={s.languages}>
          <div className={language === 'BE' ? s.lan_item : null} onClick={() => changeLanguage('BE')}>BE</div>
          <div className={language === 'EN' ? s.lan_item : null} onClick={() => changeLanguage('EN')}>EN</div>
          <div className={language === 'RU' ? s.lan_item : null} onClick={() => changeLanguage('RU')}>RU</div>
        </div> :
        null}
    </div>
  )
}