import React, { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Direction.scss'
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import s from './MapBox.module.scss'
import { useTranslation } from 'react-i18next';


export const MapBox = ({ points, center }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null)
  const [userMarker, setUserMarker] = useState(null);
  const [routeSet, setRouteSet] = useState(false);
  const [userLocation, setUserLocation] = useState(null)

  const { t } = useTranslation()

    useEffect(() => {
     const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserMarker(null)
        setUserLocation([longitude, latitude]);
      },
      (error) => {
        console.error("Geolocation error:", error.message);
      }
    );
    if (mapRef.current) {
      mapboxgl.workerClass = MapboxWorker;
      mapboxgl.accessToken = "pk.eyJ1Ijoic2h1c3RpayIsImEiOiJjbGt1d3VzcDQwNDluM2NwMDM4ZHJ6NDlrIn0.7n2_OTI_yAVrm7SoOFwLiw"
      setMap(
        new mapboxgl.Map({
          container: mapRef.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: center && center[0] ? center : [27.61291529630767, 53.91357],
          zoom: center && center[0] ? 16 : 14
        })
      )
      handleRemoveGeoMarker()
    }       
    return () => {
      navigator.geolocation.clearWatch(watchId)
      clearContainer()
    };

  }, [center]);

  useEffect(() => {
    if (map && userLocation) {
      if (!userMarker) {
        const markerElement = document.createElement('div');
        markerElement.className = 'geolocation';
        markerElement.innerHTML = '<div></div>'

        const marker = new mapboxgl.Marker({ element: markerElement })
          .setLngLat(userLocation)
          .addTo(map);
        setUserMarker(marker);
      } else {
        userMarker.setLngLat(userLocation);
      }

      async function getRoute(end, start) {
        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/walking/${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=true&continue_straight=true&geometries=geojson&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
          { method: 'GET' }
        );
        const json = await query.json();
        const data = json.routes[0];
        const route = data.geometry.coordinates;
        const geojson = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route
          }
        };
        if (map.getSource('route')) {
          map.getSource('route').setData(geojson);
        }
        else {
          map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: geojson
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': '#E05B5B',
              'line-width': 5,
              'line-opacity': 0.9
            }
          });
        }
        setRouteSet(true);
      }

      const handleMapClick = (event) => {
        const coords = Object.keys(event.lngLat).map((key) => event.lngLat[key]);

        getRoute(coords, userLocation);
      };

      map.on('click', handleMapClick);
      return () => {
        map.off('click', handleMapClick);
      };
    }
  }, [userMarker, map, userLocation]);

  const clearRoute = () => {
    if (map.getSource('route')) {
      map.getSource('route').setData({ type: 'FeatureCollection', features: [] });
    }
    setRouteSet(false);
  };

  const handleRemoveGeoMarker = () => {
    const childElements = mapRef.current.getElementsByClassName("child");
    for (let i = childElements.length - 1; i >= 0; i--) {
      mapRef.current.removeChild(childElements[i]);
    }
  };

  useEffect(() => {
    if (map && points) {
      if (mapRef.current.getElementsByClassName("mapboxgl-ctrl-fullscreen").length === 0) {
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
      }
      if (mapRef.current.getElementsByClassName("mapboxgl-ctrl-zoom-in").length === 0) {

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
      }

      points.forEach((point, index) => {
        const markerElement = document.createElement('div')
        markerElement.className = 'map_point'
        markerElement.innerText = point.place_id

        new mapboxgl.Marker({ element: markerElement })
          .setLngLat([point.lng, point.lat])
          .addTo(map);
      });
    }
  }, [map, points]);
  const clearContainer = () => {
    if (mapRef.current) {
      mapRef.current.innerHTML = ''
    }
  }

  return (

    <>
    {!userLocation && <div className='geolocation_error'>{t('geoLocationError')}</div>}
      <div ref={mapRef} style={{ width: '100%', height: '400px' }}>
        {routeSet && (
          <button className={s.clear_route} onClick={() => clearRoute()}>Очистить маршрут</button>
        )}
      </div>
    </>

  );
};

