import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getPoints = createAsyncThunk(
  'points/fetchText',
  async (language, { rejectWithValue }) => {

    try {
      const response = await fetch(`https://cbg.krokam.by/api/rest/placelocale/?lang=${language()}`)
      if (!response.ok) {

        throw new Error(response.statusText);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)


const pointsSlice = createSlice({
  name: 'points',
  initialState: {
    error: null,
    status: null,
    points: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPoints.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(getPoints.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.points = action.payload.sort((a, b) => a.place_id - b.place_id);
      })
      .addCase(getPoints.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
     
  },
})

export default pointsSlice.reducer

