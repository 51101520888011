import { useNavigate } from 'react-router-dom'
import s from './NavigationBar.module.scss'
import { ReactComponent as Arrow } from '../../assets/arrowright.svg'

export const NavigationBar = ({ mainPage, webVersion, pageName }) => {
    const navigate = useNavigate()
    const navigateToPage = (path) => {
        navigate(path)
    }
    return (
        <div className={s.navigation}>
            <span className={s.main_link} onClick={() => navigateToPage('/')}>{mainPage}</span>
            {webVersion ? <>
                {<Arrow />}
                <span className={s.main_link} onClick={() => navigateToPage('/points')}>{webVersion}</span>
            </>
                : null}
            {<Arrow />}
            <span className={s.page_name}>{pageName}</span>
        </div>
    )
}