import { useTranslation } from 'react-i18next'
import s from './Description.module.scss'
import photo from './photo.png'

export const Description = () => {
    const { t } = useTranslation()
    return (
        <section className={s.description}>
            <div className={s.title}>
                {t('titleDescription')}
            </div>
            <div className={s.body}>
                <div className={s.img}>
                    <img src={photo} alt="" />
                </div>
                <div className={s.text}>
                    <p>{t('mainText1')}</p>
                    <p>{t('mainText2')}</p>
                    <p>{t('mainText3')}</p>
                    <p>{t('mainText4')}</p>
                    
                </div>
            </div>
        </section>
    )
}