import { NavLink } from 'react-router-dom'
import s from './Buttons.module.scss'

export const Buttons = () => {

    return (
        <div className={s.buttons}>
            <a href='https://apps.apple.com/us/app/botanicalgarden/id6463234824?platform=iphone' className={s.btn}>
                ios
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.ssrlab.audioguide.botanic' className={s.btn}>
                android
            </a>
            <NavLink className={s.btn} to={'/points'}>
                <div >
                    web
                </div>
            </NavLink>

        </div>
    )
}