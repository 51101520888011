import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import s from './Point.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './swiper.css';
import { AudioPlayerComponent } from "../../Components/AudioPlayer/AudioPlayer";
import { ReactComponent as Arrow } from '../../assets/arrowright.svg'
import { MapBox } from "../../Components/MapBox/MapBox";
import { NavigationBar } from "../../Components/NavigationBar/NavigationBar";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";



export const Point = () => {
    const { points } = useSelector(state => state.points);
    const { id } = useParams();
    const { t } = useTranslation()
    const [text, setText] = useState([])
    
    const getPointData = (id) => {
        const point = points.find((point, index) => index === id);
        return point ? point : {};
    };

    const pointData = getPointData(Number(id));

    useEffect(() => {
        if (pointData.text_speaker) {
            const serverResponse = pointData.text_speaker;
            const paragraphArray = serverResponse.split('\r\n')
            const paragraphElements = paragraphArray.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))
            setText(paragraphElements)
        }
    }, [pointData])

    const pointInMap = points.filter((point, index) => index === Number(id))
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return (
        <div className={s.object}>
            <NavigationBar mainPage={t('mainNavigationBar')} webVersion={t('webNavigationBar')} pageName={pointData.place_name_locale} />

            <div className={s.title_body}>
                <NavLink to={'/points'}>
                    <Arrow className={s.arrow} />
                </NavLink>
                <h1 className={s.title}>{pointData.place_name_locale}</h1>
            </div>

            <div className={s.swiper}>
                {Object.keys(pointData).length > 0 &&
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        loop={true}
                        pagination={{ clickable: true }}
                    >
                        {pointData.place_images && Object.values(pointData.place_images).map((imageUrl, index) => (
                            <SwiperSlide key={index}>
                                <img src={imageUrl} alt='' />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }

            </div>

            <AudioPlayerComponent duration={pointData.duration} src={pointData.place_sound} />

            <div className={s.text}>
                {text}
            </div>
            <div className={s.nav_links}>
                <NavLink to={`/points`}>
                    {t('toTheList')}
                </NavLink>
                {points.length > 0 && id >= points.at(0).place_id ?
                    <NavLink to={`/points/${Number(id) - 1}`}>
                        {t('previousObject')}
                    </NavLink> : null}
                {points.length > 0 && id < points.length - 1 ?
                    <NavLink to={`/points/${Number(id) + 1}`}>
                        {t('nextObject')}
                    </NavLink> : null}
            </div>
            <div className={s.map_title}>
                {t('objectOnMap')}
            </div>
            <MapBox points={pointInMap} center={[pointData.lng, pointData.lat]} />
        </div>
    );
};

