import { useSelector } from 'react-redux'
import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './Points.module.scss'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../assets/arrowright.svg'
import { useTranslation } from 'react-i18next'

export const Points = () => {

    const { points } = useSelector(state => state.points)

    const { t } = useTranslation()

    window.scrollTo({ top: 0, behavior: 'smooth' });
    return (
        <section className={s.points_body}>
            <div className={s.nav_body}>
                <NavigationBar mainPage={t('mainNavigationBar')} pageName={t('webNavigationBar')} />
            </div>

            <div className={s.title}>
                <NavLink to={'/'}>
                    <Arrow className={s.arrow} />
                </NavLink>

                <div>
                    {t('pointsWebTitle')}
                </div>
            </div>
            <div className={s.points}>
                <div className={s.points_title}>
                    {t('pointsObjectList')}
                </div>
                <div className={s.items}>
                    {points.map((e, index) =>
                        <NavLink to={`/points/${index}`} key={index} className={s.item}>

                            <img src={e.place_logo} alt="" />
                            <div className={s.item_name}>
                                <span>{e.place_id}.</span>
                                <span>{e.place_name_locale}</span>
                            </div>


                        </NavLink>
                    )}

                </div>
            </div>
        </section>
    )
}