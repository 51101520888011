import { NavLink } from 'react-router-dom'
import s from './Header.module.scss'
import { SelectLanguage } from './SelectLanguage/SelectLanguage'
import { ReactComponent as Logo } from './image-logo.svg'
import { useTranslation } from 'react-i18next'


export const Header = () => {

    const { t } = useTranslation()

    return (
        <header className={s.header}>
            <NavLink to={'/'}>
                <div className={s.logo_body}>
                    <Logo />
                    <div className={s.text}>
                        <div>{t('logoTop')}</div>
                        <div>{t('logoBottom')}</div>
                    </div>
                </div>
            </NavLink>

            <SelectLanguage />
        </header>
    )
}