import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Footer } from './Components/Footer/Footer';
import { Header } from './Components/Header/Header';
import { Main } from './Pages/Main/Main';
import { Points } from './Pages/Points/Points';
import { Point } from './Pages/Point/Point';


export const App = () => {

  return (
    <div className="App">
      <Header />
      <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/points' element={<Points />} />
          <Route path="/points/:id" element={<Point />} />
      </Routes>      
      <Footer />
    </div>
  );
}
