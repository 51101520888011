
import { useTranslation } from 'react-i18next'
import s from './UperBlock.module.scss'
import mainImg from './main-image.png'

export const UpperBlock = () => {

    const { t } = useTranslation()

    return (
        <section className={s.wrapper}>
            <div className={s.upperBlock}>
                <div className={s.titles}>
                    <div className={s.title_high}>
                        {t('titleTop')}
                    </div>
                    <h1 className={s.title_main}>
                    {t('titleBottom')}
                    </h1>
                </div>
                <div className={s.img_body}>
                   <img className={s.img} src={mainImg} alt="" /> 
                   <div className={s.bg_line}></div>
                </div>
                
            </div>
        </section>
    )
}